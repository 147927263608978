import React, { useState, useEffect } from "react"
import "../sass/main.scss"
import { Layout, SEO, Menu, Carousel, FormComponent } from "../components"
import { Row, Col, Container } from "react-bootstrap"
import Countries from "../model/Countries"
import { FormInput } from "../model/Form"
import { Link } from "gatsby"
import { baseURL } from "../api/config"
const ContactPage = (props: any) => {
  const [darkMode, setDarkMode] = useState(false)
  const [activeIndex, setActiveIndex] = useState(0)
  const [formNewBusiness, setFormNewBusiness] = useState<FormInput[]>([
    {
      name: "name",
      label: "Name",
      value: "",
      error: null,
      validation: {
        presence: { allowEmpty: false, message: "^Field required" },
      },
      type: "text",
      floatingLabel: true,
    },
    {
      name: "email",
      label: "Email",
      value: "",
      error: null,
      validation: {
        presence: { allowEmpty: false, message: "^Field required" },
        email: { message: "^Is not a valid email" },
      },
      type: "email",
      floatingLabel: true,
    },
    {
      name: "organisation",
      label: "Organisation",
      value: "",
      error: null,
      validation: {
        presence: { allowEmpty: false, message: "^Field required" },
      },
      type: "text",
      floatingLabel: true,
    },
    {
      name: "role",
      label: "Role",
      value: "",
      error: null,
      validation: {
        presence: { allowEmpty: false, message: "^Field required" },
      },
      type: "text",
      floatingLabel: true,
    },
    {
      name: "country",
      label: "Country",
      value: "",
      error: null,
      validation: {
        presence: { allowEmpty: false, message: "^Field required" },
      },
      type: "select",
      fullRow: true,
      options: Countries,
      floatingLabel: true,
    },
    {
      name: "message",
      label: "Short message",
      value: "",
      error: null,
      validation: {
        presence: { allowEmpty: false, message: "^Field required" },
      },
      type: "textarea",
      fullCol: true,
    },
    {
      name: "subscribe_new_business",
      label: "I’d like to receive updates from Satalia",
      value: false,
      error: null,
      validation: {},
      type: "checkbox",
      fullCol: true,
    },
  ])
  const formSpeakingOpportunity: FormInput[] = [
    {
      name: "event_name",
      label: "Event Name",
      value: "",
      error: null,
      validation: {
        presence: { allowEmpty: false, message: "^Field required" },
      },
      type: "text",
      floatingLabel: true,
    },
    {
      name: "organisation_name",
      label: "Organisation name",
      value: "",
      error: null,
      validation: {
        presence: { allowEmpty: false, message: "^Field required" },
      },
      type: "text",
      floatingLabel: true,
    },
    {
      name: "event_location",
      label: "Event location ",
      value: "",
      error: null,
      validation: {
        presence: { allowEmpty: false, message: "^Field required" },
      },
      type: "text",
      floatingLabel: true,
    },
    {
      name: "date_requested",
      label: "Date requested  (dd/mm/yy)",
      value: "",
      error: null,
      validation: {
        presence: { allowEmpty: false, message: "^Field required" },
      },
      type: "date",
      floatingLabel: true,
    },
    {
      name: "expected_audience_size",
      label: "Expected audience size",
      value: "",
      error: null,
      validation: {
        presence: { allowEmpty: false, message: "^Field required" },
      },
      type: "text",
      fullRow: true,
      floatingLabel: true,
    },
    {
      name: "name",
      label: "Your name ",
      value: "",
      error: null,
      validation: {
        presence: { allowEmpty: false, message: "^Field required" },
      },
      type: "text",
      floatingLabel: true,
    },
    {
      name: "email",
      label: "Your email address",
      value: "",
      error: null,
      validation: {
        presence: { allowEmpty: false, message: "^Field required" },
        email: { message: "^Is not a valid email" },
      },
      type: "email",
      floatingLabel: true,
    },
    {
      name: "message",
      label: "Short message",
      value: "",
      error: null,
      validation: {
        presence: { allowEmpty: false, message: "^Field required" },
      },
      type: "textarea",
      fullCol: true,
    },
    {
      name: "subscribe_speaking_opportunity",
      label: "I’d like to receive updates from Satalia",
      value: false,
      error: null,
      validation: {},
      type: "checkbox",
      fullCol: true,
    },
  ]
  const formOpenPositions: FormInput[] = [
    {
      name: "name",
      label: "Name",
      value: "",
      error: null,
      validation: {
        presence: { allowEmpty: false, message: "^Field required" },
      },
      type: "text",
      floatingLabel: true,
    },
    {
      name: "email",
      label: "Email",
      value: "",
      error: null,
      validation: {
        presence: { allowEmpty: false, message: "^Field required" },
        email: { message: "^Is not a valid email" },
      },
      type: "email",
      floatingLabel: true,
    },
    {
      name: "message",
      label: "Short message",
      value: "",
      error: null,
      validation: {
        presence: { allowEmpty: false, message: "^Field required" },
      },
      type: "textarea",
      fullCol: true,
    },
    {
      name: "subscribe_open_positions",
      label: "I’d like to receive updates from Satalia",
      value: false,
      error: null,
      validation: {},
      type: "checkbox",
      fullCol: true,
    },
  ]
  useEffect(() => {
    fetch("https://ipapi.co/json/")
      .then(function(response) {
        return response.json()
      })
      .then(function(data) {
        let form = [...formNewBusiness]
        form.find((x) => x.name === "country").value = data.country_name
        setFormNewBusiness(form)
      })
    let hash = props.location.hash
    if (hash) {
      if (hash === "#speaking-opportunity") setActiveIndex(1)
      else if (hash === "#open-positions") setActiveIndex(2)
      else setActiveIndex(0)
    }
  }, [])

  useEffect(() => {}, [darkMode, activeIndex])

  return (
    <Layout step={2} darkMode={darkMode}>
      <SEO
        title="Contact us | Satalia | Enterprise AI"
        description="Contact us here to discuss an opportunity, partnership or media enquiry."
        twitterTitle="Contact us | Satalia | Enterprise AI"
        twitterDescription="Contact us here to discuss an opportunity, partnership or media enquiry."
        openGraphTitle="Contact us | Satalia | Enterprise AI"
        openGraphDescription="Discuss an opportunity, partnership or media enquiry."
      />
      <Container>
        <Row className="pb-75">
          <Col className="d-none d-lg-block" lg="4">
            <div className="st-block st-block--head h-auto">
              <Menu id="content-menu" />
            </div>
          </Col>
          <Col xs="3" sm="8" md="10" lg="6">
            <Row>
              <Col xs="3" sm="8" md="10" lg="12">
                <div className="st-block st-block--head st-block--transparent">
                  <h1 className="font-weight-normal">
                    Contact us about an opportunity, partnership or media
                    enquiry
                  </h1>
                </div>
              </Col>
              <Col xs="3" sm="8" md="10" lg="12">
                <div className="st-block st-block--transparent contact-form">
                  <Carousel
                    activeIndex={activeIndex}
                    autoPlay={false}
                    navigationEvent={(index: number) => {
                      setActiveIndex(index)
                      if (index === 2) {
                        setDarkMode(true)
                      } else {
                        setDarkMode(false)
                      }
                    }}
                  >
                    <Carousel.Tabs key="tabs">
                      <Row>
                        <Col
                          xs="3"
                          sm="2"
                          md="3"
                          lg="4"
                          xl="4"
                          className="st-carousel__tab-column"
                        >
                          <div className="pb-0">
                            <Carousel.Tab>
                              <a
                                href="#new-business"
                                onClick={() => setDarkMode(false)}
                              >
                                New
                                <br /> business
                              </a>
                            </Carousel.Tab>
                          </div>
                        </Col>
                        <Col
                          xs="3"
                          sm="2"
                          md="3"
                          lg="4"
                          xl="4"
                          className="st-carousel__tab-column"
                        >
                          <div className="pb-0">
                            <Carousel.Tab>
                              <a
                                href="#speaking-opportunity"
                                onClick={() => setDarkMode(false)}
                              >
                                Speaking
                                <br /> opportunity
                              </a>
                            </Carousel.Tab>
                          </div>
                        </Col>
                        <Col
                          xs="3"
                          sm="2"
                          md="3"
                          lg="4"
                          xl="4"
                          className="st-carousel__tab-column"
                        >
                          <div className="pb-0">
                            <Carousel.Tab>
                              <a
                                href="#open-positions"
                                onClick={() => setDarkMode(true)}
                              >
                                Open
                                <br /> positions
                              </a>
                            </Carousel.Tab>
                          </div>
                        </Col>
                      </Row>
                      <Carousel.Navigations>
                        <li
                          className="st-carousel__navigation st-carousel__navigation--left st-carousel__navigation--disabled"
                          data-navigate="previous"
                        ></li>
                        <li
                          className="st-carousel__navigation st-carousel__navigation--right"
                          data-navigate="next"
                        ></li>
                      </Carousel.Navigations>
                    </Carousel.Tabs>
                    <Row>
                      <Col xs="3" sm="8" md="9" lg="12" xl="12">
                        <Carousel.Contents
                          key="contents"
                          className="st-carousel__contents--tranparent"
                        >
                          <Carousel.Content>
                            <h3>Interested in working with us?</h3>
                            <p>
                              Please provide a brief description of your
                              business inquiry and we'll get back to you as soon
                              as we can.
                            </p>
                            <FormComponent
                              action={`${baseURL}/frontend/contact/new_business`}
                              name="business"
                              inputs={formNewBusiness}
                            ></FormComponent>
                          </Carousel.Content>
                          <Carousel.Content>
                            <h3>Want to hear our talk?</h3>
                            <p>
                              Please take a few moments to answer some brief
                              questions about your event.
                            </p>
                            <FormComponent
                              action={`${baseURL}/frontend/contact/speaking_opportunity`}
                              name="opportunity"
                              inputs={formSpeakingOpportunity}
                            ></FormComponent>
                          </Carousel.Content>
                          <Carousel.Content>
                            <h3>Interested in joining us?</h3>
                            <p>
                              See our current openings on the{" "}
                              <Link
                                className="text-responsive"
                                title="Careers"
                                to="/careers"
                              >
                                careers
                              </Link>{" "}
                              page. Contact if you have application questions
                              related with recruitment.
                            </p>
                            <FormComponent
                              action={`${baseURL}/frontend/contact/open_positions`}
                              name="positions"
                              darkMode={true}
                              inputs={formOpenPositions}
                            ></FormComponent>
                          </Carousel.Content>
                        </Carousel.Contents>
                      </Col>
                    </Row>
                  </Carousel>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default ContactPage
